import { createReducer } from '@reduxjs/toolkit';
import {
  GET_BUILDING,
  ADD_BUILDING,
  EDIT_BUILDING,
  GET_BUILDINGS,
  GET_EDITED_BUILDINGS,
  GET_BUILDING_UNITS,
  SET_GLOBAL_BUILDING,
  SET_GLOBAL_BUILDING_ID,
  SET_BUILDING_IMAGE,
  CLEAR_BUILDING,
  SET_BUILDING,
  SET_BUILDING_AVATAR,
  CLEAR_BUILDINGS,
  CLEAR_ERROR,
  SEARCH_BUILDINGS,
  REMOVE_BUILDING,
  SET_ALL_BUILDINGS,
  GET_ALL_BUILDINGS,
  SET_CACHED_BUILDINGS,
  SET_LOADING_CACHED_BUILDINGS,
  RESET_GLOBAL_BUILDINGS,
  SET_GLOBAL_BUILDING_STATUS,
  GET_DELIVERY_CODES,
  ADD_DELIVERY_CODE,
  REMOVE_DELIVERY_CODE,
  GET_BUILDING_SUITES,
  RESET_SUITE_IN_BUILDING,
  INCREMENT_BUILDING_EVENT_COUNT,
  GET_BUILDING_DEVICES,
  SHOW_GLOBAL_BUILDINGS_DROPDOWN,
  SET_GLOBAL_SELECTED_BUILDING
} from '../constants';
import { requestSuccess, requestFail, requestPending } from '../../utils/status';
import { orderBy } from 'lodash';

const initialState = {
  buildings: [], // paginated buildings given the org, used in many places
  allBuildings: [], // non-paginated buildings given the org
  status: 'INIT',
  buildingSuitesStatus: '',
  errors: null,
  units: [],
  unit: {},
  suites: null,
  suitesPagination: {
    currentPage: 1,
    perPage: null,
    total: null
  },
  buildingChangeEventCount: 0,
  globalBuildingId: 0,
  globalBuildingName: '',
  globalSelectedBuilding: undefined,
  buildingsPerRole: {
    data: [],
    pagination: null,
    status: ''
  }, // paginated buildings given the org, only used in global buildings
  suites: null,
  suitesPagination: {
    currentPage: 1,
    perPage: null,
    total: null
  },
  avatar: '',
  building: {},
  pagination: {
    currentPage: 1,
    perPage: null,
    total: null
  },
  cachedOrgBuildings: {}, // admins only, keys are org id and values are arrays of buildings in the org
  loadingCachedBuildings: false,
  deliveryCodes: [],
  building_devices: [],
  building_devices_status: '',
  building_devices_error: null,
  showGlobalBuildingsDropdown: true
};

export default createReducer(initialState, {
  [SHOW_GLOBAL_BUILDINGS_DROPDOWN]: (state, { payload }) => ({
    ...state,
    showGlobalBuildingsDropdown: payload
  }),
  [CLEAR_ERROR]: (state) => ({
    ...state,
    errors: null
  }),
  [RESET_SUITE_IN_BUILDING]: (state) => ({
    ...state,
    suites: null,
    suitesPagination: {
      currentPage: 1,
      perPage: null,
      total: null
    },
    status: 'INIT'
  }),

  [requestSuccess(GET_BUILDING_SUITES)]: (state, { payload }) => {
    return {
      ...state,
      suites: payload?.data,
      suitesPagination: {
        currentPage: payload?.current_page,
        perPage: payload?.per_page,
        total: payload?.total
      },
      buildingSuitesStatus: requestSuccess(GET_BUILDING_SUITES),
      status: requestSuccess(GET_BUILDING_SUITES),
      error: null
    };
  },
  [requestPending(GET_BUILDING_SUITES)]: (state) => ({
    ...state,
    buildingSuitesStatus: requestPending(GET_BUILDING_SUITES),
    status: requestPending(GET_BUILDING_SUITES)
  }),
  [requestFail(GET_BUILDING_SUITES)]: (state, { payload }) => {
    return {
      ...state,
      buildingSuitesStatus: requestFail(GET_BUILDING_SUITES),
      status: requestFail(GET_BUILDING_SUITES),
      suitesPagination: {
        currentPage: 1,
        perPage: null,
        total: null
      },
      error: payload.message,
      suites: []
    };
  },

  [CLEAR_BUILDINGS]: (state, { payload }) => ({
    ...state,
    buildings: []
  }),
  [SET_BUILDING_AVATAR]: (state, { payload }) => ({
    ...state,
    avatar: payload.avatar
  }),

  [SET_BUILDING]: (state, { payload }) => ({
    ...state,
    building: payload
  }),

  [CLEAR_BUILDING]: (state, { payload }) => ({
    ...state,
    building: {}
  }),

  [SET_ALL_BUILDINGS]: (state, { payload }) => {
    return {
      ...state,
      allBuildings: payload
    };
  },

  [RESET_SUITE_IN_BUILDING]: (state) => ({
    ...state,
    suites: null,
    suitesPagination: {
      currentPage: 1,
      perPage: null,
      total: null
    },
    status: 'INIT'
  }),

  [INCREMENT_BUILDING_EVENT_COUNT]: (state) => {
    return {
      ...state,
      buildingChangeEventCount: state.buildingChangeEventCount + 1
    };
  },

  [requestSuccess(SEARCH_BUILDINGS)]: (state, { payload }) => ({
    ...state,
    buildings: payload.data,

    pagination: {
      currentPage: payload.current_page,
      perPage: payload.per_page,
      total: payload.total
    },

    status: requestSuccess(SEARCH_BUILDINGS),
    error: null
  }),

  [requestPending(SEARCH_BUILDINGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SEARCH_BUILDINGS)
  }),

  [requestFail(SEARCH_BUILDINGS)]: (state, { payload }) => ({
    ...state,
    buildings: [],
    status: requestFail(SEARCH_BUILDINGS),
    error: payload?.data?.message
  }),

  [requestSuccess(REMOVE_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(REMOVE_BUILDING),
    error: null
  }),

  [requestPending(REMOVE_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(REMOVE_BUILDING)
  }),

  [requestFail(REMOVE_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestFail(REMOVE_BUILDING),
    error: payload.data.message
  }),

  [requestSuccess(GET_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_BUILDING),
    error: '',
    building: payload
  }),

  [requestPending(GET_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_BUILDING)
  }),

  [requestFail(GET_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_BUILDING),
    error: payload.data,
    building: {}
  }),

  [SET_GLOBAL_BUILDING_ID]: (state, { payload }) => ({
    ...state,
    globalBuildingId: payload.globalBuildingId,
    globalBuildingName: payload.globalBuildingName
  }),

  [SET_GLOBAL_SELECTED_BUILDING]: (state, { payload }) => ({
    ...state,
    globalSelectedBuilding: payload
  }),

  [requestSuccess(SET_BUILDING_IMAGE)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(SET_BUILDING_IMAGE),
    avatar: payload.filename
  }),

  [requestFail(SET_BUILDING_IMAGE)]: (state, { payload }) => ({
    ...state,
    status: requestFail(SET_BUILDING_IMAGE),
    avatar: '',
    error: payload.data
  }),
  [RESET_GLOBAL_BUILDINGS]: (state) => ({
    ...state,
    buildingsPerRole: {
      data: [],
      pagination: null,
      status: ''
    }
  }),
  [SET_GLOBAL_BUILDING_STATUS]: (state, { payload }) => {
    return {
      ...state,
      buildingsPerRole: {
        ...state.buildingsPerRole,
        status: payload.status
      }
    };
  },
  [requestSuccess(SET_GLOBAL_BUILDING)]: (state, { payload }) => {
    const { buildingsPerRole } = state;

    const pagination = payload.pagination;
    Object.keys(pagination).forEach((key) => {
      pagination[key] = parseInt(payload.pagination[key], 10);
    });

    const isSamePage =
      buildingsPerRole.pagination &&
      pagination['pagination-current-page'] ===
        buildingsPerRole.pagination['pagination-current-page'];
    const isSameOrg =
      payload.data?.length > 0 &&
      buildingsPerRole.data?.length > 0 &&
      buildingsPerRole.data[0].org_id === payload.data[0].org_id;
    const isNextPage =
      buildingsPerRole.pagination &&
      pagination['pagination-current-page'] ===
        buildingsPerRole.pagination['pagination-current-page'] + 1;
    const isSameBuildings = () => {
      if (payload.data.length !== buildingsPerRole.data.length) {
        return false;
      }
      let isDifferent = false;
      payload.data.forEach((building, idx) => {
        if (isDifferent) {
          return;
        }
        isDifferent = building.id === buildingsPerRole.data[idx].id;
      });
      return !isDifferent;
    };

    // go through same page to see if anything is modified
    if (isSameOrg) {
      if (isNextPage) {
        return {
          ...state,
          status: requestSuccess(SET_GLOBAL_BUILDING),
          buildingsPerRole: {
            pagination: pagination,
            data: [...state.buildingsPerRole.data, ...payload.data],
            status: requestSuccess(SET_GLOBAL_BUILDING)
          }
        };
      } else if (isSamePage && isSameBuildings()) {
        return {
          ...state,
          status: requestSuccess(SET_GLOBAL_BUILDING),
          buildingsPerRole: {
            ...state.buildingsPerRole,
            status: requestSuccess(SET_GLOBAL_BUILDING)
          }
        };
      }
    }
    return {
      ...state,
      status: requestSuccess(SET_GLOBAL_BUILDING),
      buildingsPerRole: {
        pagination: pagination,
        data: [...payload.data],
        status: requestSuccess(SET_GLOBAL_BUILDING)
      }
    };
  },

  [requestFail(SET_GLOBAL_BUILDING)]: (state, { payload }) => ({
    ...state,
    globalBuildingId: -1,
    status: requestFail(SET_GLOBAL_BUILDING),
    error: payload.data,
    buildingsPerRole: {
      data: [],
      pagination: {},
      status: requestFail(SET_GLOBAL_BUILDING)
    }
  }),

  [GET_EDITED_BUILDINGS]: (state, { payload }) => {
    return {
      ...state
    };
  },

  [requestSuccess(GET_BUILDING_UNITS)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(GET_BUILDING_UNITS),
    units: orderBy(payload, ['unit_num'], ['asc'])
  }),

  [requestFail(GET_BUILDING_UNITS)]: (state, { payload }) => ({
    ...state,
    status: requestFail(GET_BUILDING_UNITS),
    error: payload.error,
    units: []
  }),

  [requestSuccess(ADD_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(ADD_BUILDING),
    error: null
  }),
  [requestPending(ADD_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_BUILDING)
  }),

  [requestFail(ADD_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestFail(ADD_BUILDING),
    error: payload.data
  }),

  [requestSuccess(EDIT_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestSuccess(EDIT_BUILDING),
    error: null
  }),
  [requestPending(EDIT_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestPending(EDIT_BUILDING)
  }),

  [requestFail(EDIT_BUILDING)]: (state, { payload }) => ({
    ...state,
    status: requestFail(EDIT_BUILDING),
    error: payload.data
  }),

  [requestSuccess(GET_BUILDINGS)]: (state, { payload }) => {
    return {
      ...state,
      buildings: payload?.data,
      pagination: {
        currentPage: payload?.pagination['pagination-current-page'],
        perPage: payload?.pagination['pagination-per-page'],
        total: payload?.pagination['pagination-total']
      },
      status: requestSuccess(GET_BUILDINGS),
      error: null
    };
  },

  [requestPending(GET_BUILDINGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_BUILDINGS)
  }),

  [requestFail(GET_BUILDINGS)]: (state, { payload }) => {
    return {
      ...state,
      buildings: [],
      status: requestFail(GET_BUILDINGS),
      error: payload.data.message
    };
  },

  [requestSuccess(GET_BUILDING_DEVICES)]: (state, { payload }) => {
    return {
      ...state,
      building_devices: payload,
      building_devices_status: requestSuccess(GET_BUILDING_DEVICES),
      building_devices_error: null
    };
  },

  [requestPending(GET_BUILDING_DEVICES)]: (state) => ({
    ...state,
    building_devices_status: requestPending(GET_BUILDING_DEVICES),
    building_devices_error: null
  }),

  [requestFail(GET_BUILDING_DEVICES)]: (state, { payload }) => {
    return {
      ...state,
      building_devices: [],
      building_devices_status: requestFail(GET_BUILDING_DEVICES),
      building_devices_error: payload.data.message
    };
  },

  [requestSuccess(GET_ALL_BUILDINGS)]: (state, { payload }) => {
    return {
      ...state,
      allBuildings: payload?.data,
      status: requestSuccess(GET_ALL_BUILDINGS),
      error: null
    };
  },

  [requestPending(GET_ALL_BUILDINGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_ALL_BUILDINGS)
  }),

  [requestFail(GET_ALL_BUILDINGS)]: (state, { payload }) => {
    return {
      ...state,
      allBuildings: [],
      status: requestPending(GET_ALL_BUILDINGS),
      error: payload.data.message
    };
  },
  [SET_LOADING_CACHED_BUILDINGS]: (state, { payload }) => {
    const { orgId, isLoading } = payload;
    return {
      ...state,
      loadingCachedBuildings: { orgId, isLoading }
    };
  },
  [requestSuccess(SET_CACHED_BUILDINGS)]: (state, { payload }) => {
    const { orgId } = state.loadingCachedBuildings;
    const orgInString = orgId.toString();
    const orgBuildings = { ...state.cachedOrgBuildings };
    // if the last building in the org is deleted, remove the org entry from cachedOrgBuildings
    if (payload.length === 0 && orgId > 0) {
      delete orgBuildings[orgInString];
      return {
        ...state,
        cachedOrgBuildings: orgBuildings,
        status: requestSuccess(SET_CACHED_BUILDINGS),
        error: null
      };
    }
    const newCachedOrgBuildings = {};
    // result buildings list are not grouped/ordered by org id
    payload.forEach((building) => {
      const { id, name, org_id } = building;
      const orgId = org_id.toString();
      if (newCachedOrgBuildings[orgId]?.length > 0) {
        newCachedOrgBuildings[orgId].push({ id, name });
      } else {
        newCachedOrgBuildings[orgId] = [{ id, name }];
      }
    });
    if (orgId === 0) {
      return {
        ...state,
        cachedOrgBuildings: newCachedOrgBuildings,
        status: requestSuccess(SET_CACHED_BUILDINGS),
        error: null
      };
    }
    // one org only
    orgBuildings[orgInString] = newCachedOrgBuildings[orgInString];
    return {
      ...state,
      cachedOrgBuildings: orgBuildings,
      status: requestSuccess(SET_CACHED_BUILDINGS),
      error: null
    };
  },

  [requestPending(SET_CACHED_BUILDINGS)]: (state, { payload }) => ({
    ...state,
    status: requestPending(SET_CACHED_BUILDINGS)
  }),

  [requestFail(SET_CACHED_BUILDINGS)]: (state, { payload }) => {
    return {
      ...state,
      status: requestFail(SET_CACHED_BUILDINGS),
      error: payload.data
    };
  },

  [requestSuccess(GET_DELIVERY_CODES)]: (state, { payload }) => {
    return {
      ...state,
      deliveryCodes: payload,
      status: requestSuccess(GET_DELIVERY_CODES),
      error: null
    };
  },
  [requestPending(GET_DELIVERY_CODES)]: (state, { payload }) => ({
    ...state,
    status: requestPending(GET_DELIVERY_CODES)
  }),

  [requestFail(GET_DELIVERY_CODES)]: (state, { payload }) => {
    return {
      ...state,
      deliveryCodes: [],
      status: requestPending(GET_DELIVERY_CODES),
      error: payload.message
    };
  },
  [requestSuccess(ADD_DELIVERY_CODE)]: (state, { payload }) => {
    return {
      ...state,
      deliveryCodes: [],
      status: requestSuccess(ADD_DELIVERY_CODE),
      error: null
    };
  },
  [requestPending(ADD_DELIVERY_CODE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(ADD_DELIVERY_CODE)
  }),

  [requestFail(ADD_DELIVERY_CODE)]: (state, { payload }) => {
    return {
      ...state,
      status: requestPending(ADD_DELIVERY_CODE),
      error: payload.message
    };
  },
  [requestSuccess(REMOVE_DELIVERY_CODE)]: (state, { payload }) => {
    return {
      ...state,
      deliveryCodes: [],
      status: requestSuccess(REMOVE_DELIVERY_CODE),
      error: null
    };
  },
  [requestPending(REMOVE_DELIVERY_CODE)]: (state, { payload }) => ({
    ...state,
    status: requestPending(REMOVE_DELIVERY_CODE)
  }),
  [requestFail(REMOVE_DELIVERY_CODE)]: (state, { payload }) => {
    return {
      ...state,
      status: requestPending(REMOVE_DELIVERY_CODE),
      error: payload.message
    };
  },
  [requestSuccess(GET_BUILDING_SUITES)]: (state, { payload }) => {
    return {
      ...state,
      suites: payload?.data,
      suitesPagination: {
        currentPage: payload?.current_page,
        perPage: payload?.per_page,
        total: payload?.total
      },
      buildingSuitesStatus: requestSuccess(GET_BUILDING_SUITES),
      status: requestSuccess(GET_BUILDING_SUITES),
      error: null
    };
  },
  [requestPending(GET_BUILDING_SUITES)]: (state) => ({
    ...state,
    buildingSuitesStatus: requestPending(GET_BUILDING_SUITES),
    status: requestPending(GET_BUILDING_SUITES)
  }),
  [requestFail(GET_BUILDING_SUITES)]: (state, { payload }) => {
    return {
      ...state,
      buildingSuitesStatus: requestFail(GET_BUILDING_SUITES),
      status: requestFail(GET_BUILDING_SUITES),
      suitesPagination: {
        currentPage: 1,
        perPage: null,
        total: null
      },
      error: payload.message,
      suites: []
    };
  }
});
