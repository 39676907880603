import React, { useEffect, useState } from 'react';
import { setToast } from '../../../../redux/actions/window';
import { addUnit, clearUnitError, getUnits, setUserUnit } from '../../../../redux/actions/unit';
import { useDispatch, useSelector } from 'react-redux';
import { Formik } from 'formik';
import { CForm, CFormGroup, CRow, CCol } from '@coreui/react';
import * as Yup from 'yup';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import UserAsyncDropDown from '../../../../components/UserAsyncDropDown';
import { setUser } from '../../../../redux/actions/user';
import { getBuilding, getBuildingSuites } from '../../../../redux/actions/building';
import ModalFooter from '../../../../components/ModalFooter';
import { useTranslation } from 'react-i18next';
import { setGlobalBuildingStatus, setGlobalBuilding } from '../../../../redux/actions/building';
import { requestPending } from '../../../../utils/status';
import { SET_GLOBAL_BUILDING } from '../../../../redux/constants';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../../settings/SettingsModals';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import { SettingsItemSubTitle } from '../../../settings/SettingsItem';
import { openModal } from '../../../../helper/modals';
import { Select } from '../../../../components/ui/Select';
import { ErrorText } from '../../../../components/utils/AvatarCropper';

const AddUnit = () => {
  const { t } = useTranslation();
  const unitLabels = {
    building: t('Building'),
    unit_no: t('Unit number'),
    floor_no: t('Floor'),
    bedrooms: t('Bedrooms'),
    bathrooms: t('Bathrooms'),
    area: t('Area'),
    resident: t('Resident'),
    square_feet: t('Sq.ft'),
    monthly_rent: t('Monthly Rent'),
    primary_resident: t('Primary Resident'),
    co_residents: t('Co-Residents'),
    suite_no: t('Suite number')
  };
  const dispatch = useDispatch();
  const { globalOrgId, orgConfig } = useSelector((state) => state.Organization);
  const { building, buildingsPerRole, suites, suitesPagination } = useSelector(
    (state) => state.building
  );
  const { error, status } = useSelector((state) => state.unit);
  const { modal } = useSelector((state) => state.headerModal);
  const [value, setValue] = useState(undefined);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [suiteIds, setSuiteIds] = useState([]);

  const validationSchema = function (values) {
    return Yup.object().shape({
      unit_num: Yup.string().required(
        orgConfig?.config?.suite_enabled
          ? t('Room Number is required')
          : t('Unit Number is required')
      ),
      bedrooms: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(99, t('Not more than 2 characters'))
        .typeError(t('Only numbers')),
      bathrooms: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(99, t('Not more than 2 characters'))
        .typeError(t('Only numbers')),
      area: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(999999, t('Not more than 6 characters'))
        .typeError(t('Only numbers')),
      floor: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(999, t('Not more than 3 characters'))
        .typeError(t('Only numbers'))
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  const initialValues = {
    bedrooms: undefined,
    bathrooms: undefined,
    area: undefined,
    unit_num: '',
    floor: undefined
  };

  const createUser = (unitObj) => {
    dispatch(getBuilding({ id: unitObj.building_id }));

    const unitUserData = {
      ...unitObj,
      org_id: building.org_id
    };

    dispatch(setUserUnit({ ...unitUserData }));
    dispatch(
      changeOpenedModal({
        modal: 'AddUser'
      })
    );
  };

  const onSubmit = (values, createUnitUser) => {
    setIsSubmitting(true);
    const subValues = {
      ...values,
      area: values.area ? values.area : 0,
      bathrooms: values.bathrooms ? values.bathrooms : 0,
      bedrooms: values.bedrooms ? values.bedrooms : 0,
      floor: values.floor ? values.floor : 0,
      owned: false,
      user_id: value?.value ? parseInt(value.value) : 0,
      building_id: building.id
    };

    if (orgConfig?.config?.suite_enabled && values?.suite !== -1) {
      subValues.suite_id = values?.suite;
    }

    dispatch(
      addUnit({
        body: subValues,
        success: () => {
          dispatch(
            getBuildingSuites({
              orgId: building.org_id,
              buildingId: building.id,
              page: suitesPagination?.currentPage,
              finally: () => {
                setIsSubmitting(false);
                dispatch(
                  changeOpenedModal({
                    modal: ''
                  })
                );
                dispatch(setUser({}));

                dispatch(getUnits({ id: building.id, page: 1 }));

                const globalBuildings = buildingsPerRole?.data.map((building) => building.id) || [];
                if (globalBuildings.includes(building.id)) {
                  dispatch(
                    setGlobalBuildingStatus({ status: requestPending(SET_GLOBAL_BUILDING) })
                  );
                  dispatch(
                    setGlobalBuilding({
                      id: globalOrgId
                    })
                  );
                }
                dispatch(
                  setToast({
                    toastShow: true,
                    toastMessage: orgConfig?.config?.suite_enabled
                      ? t('Room has been successfully created!')
                      : t('Unit has been successfully created!')
                  })
                );
                createUnitUser === true && createUser(subValues);
              }
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: orgConfig?.config?.suite_enabled
                ? t('Room Creation Failed!')
                : t('Unit Creation Failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
    setValue({});
  };

  const onClose = () => {
    dispatch(setUser({}));
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
    dispatch(clearUnitError());
    setIsSubmitting(false);
    setValue({});
  };

  const handleCancel = () => {
    onClose();
  };

  useEffect(() => {
    if (suites?.length > 0 && orgConfig?.config?.suite_enabled) {
      const suitesIds = suites.map((suite) => ({ id: suite.id, name: suite.name }));
      const suiteIdsWithNoUnit = [{ id: -1, name: 'No Unit' }, ...suitesIds];
      setSuiteIds(suiteIdsWithNoUnit);
    }
  }, [suites, orgConfig?.config?.suite_enabled]);

  return (
    modal === 'addUnit' && (
      <Formik
        initialValues={initialValues}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}
        validateOnChange={false}>
        {({
          values,
          errors,
          dirty,
          isValid,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) => (
          <CForm onSubmit={handleSubmit} noValidate name="AddUnitForm">
            <StyledModal
              show={modal === 'addUnit'}
              onClose={onClose}
              style={{ maxWidth: '450px' }}
              closeOnBackdrop={false}>
              <ModalHeader
                title={t(orgConfig?.config?.suite_enabled ? 'Add New Room' : 'Add New Unit')}
              />
              <StyledModalBody>
                <CRow className="mt-4">
                  <CCol>
                    <CFormGroup>
                      <InputSeparator>
                        <SettingsModalBoldText>Building</SettingsModalBoldText>
                        <SettingsModalInput
                          label={unitLabels.building}
                          onBlur={handleBlur}
                          valid={!errors.building_id}
                          type="text"
                          id="building_id"
                          name="building_id"
                          disabled={true}
                          value={building.name}
                          error={errors.building_id}
                        />
                        {errors.building_id && <ErrorText>{errors.building_id}</ErrorText>}
                      </InputSeparator>
                    </CFormGroup>
                  </CCol>
                  <CCol>
                    <CFormGroup>
                      <InputSeparator>
                        <SettingsModalBoldText>
                          {orgConfig?.config?.suite_enabled ? 'Room Num' : 'Unit Num'}
                        </SettingsModalBoldText>
                        <SettingsModalInput
                          label={unitLabels.unit_no}
                          type="string"
                          id="unit_num"
                          name="unit_num"
                          placeholder={t(
                            orgConfig?.config?.suite_enabled ? 'Room Num...' : 'Unit Num...'
                          )}
                          valid={!errors.unit_num && touched.unit_num}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.unit_num}
                          error={errors.unit_num}
                        />
                        {errors.unit_num && <ErrorText>{errors.unit_num}</ErrorText>}
                      </InputSeparator>
                    </CFormGroup>
                  </CCol>
                </CRow>
                <CFormGroup row>
                  <CCol>
                    <InputSeparator>
                      <SettingsModalBoldText>Floor</SettingsModalBoldText>
                      <SettingsModalInput
                        label={unitLabels.floor_no}
                        type="number"
                        id="floor"
                        name="floor"
                        className="form-control"
                        placeholder={t('Floor...')}
                        valid={!errors.floor && touched.floor}
                        onChange={(e) =>
                          e.target.value === 0
                            ? setFieldValue('floor', 0)
                            : setFieldValue('floor', parseInt(e.target.value))
                        }
                        onKeyDown={(e) =>
                          ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                        }
                        onBlur={handleBlur}
                        value={values.floor}
                        error={errors.floor}
                      />
                      {errors.floor && <ErrorText>{errors.floor}</ErrorText>}
                    </InputSeparator>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol>
                    <InputSeparator>
                      <SettingsModalBoldText>Bedrooms</SettingsModalBoldText>
                      <SettingsModalInput
                        label={unitLabels.bedrooms}
                        type="number"
                        className="form-control"
                        id="bedrooms"
                        name="bedrooms"
                        placeholder={t('Bedrooms...')}
                        valid={!errors.bedrooms && touched.bedrooms}
                        onChange={(e) =>
                          e.target.value === 0
                            ? setFieldValue('bedrooms', 0)
                            : setFieldValue('bedrooms', parseInt(e.target.value))
                        }
                        onBlur={handleBlur}
                        value={values.bedrooms}
                        error={errors.bedrooms}
                        onKeyDown={(e) =>
                          ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                        }
                      />
                      {errors.bedrooms && <ErrorText>{errors.bedrooms}</ErrorText>}
                    </InputSeparator>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol>
                    <InputSeparator>
                      <SettingsModalBoldText>Bathrooms</SettingsModalBoldText>
                      <SettingsModalInput
                        label={unitLabels.bathrooms}
                        type="number"
                        className="form-control"
                        id="bathrooms"
                        name="bathrooms"
                        placeholder={t('Bathrooms...')}
                        valid={!errors.bathrooms && touched.bathrooms}
                        onChange={(e) =>
                          e.target.value === 0
                            ? setFieldValue('bathrooms', 0)
                            : setFieldValue('bathrooms', parseInt(e.target.value))
                        }
                        onBlur={handleBlur}
                        value={values.bathrooms}
                        error={errors.bathrooms}
                        onKeyDown={(e) =>
                          ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                        }
                      />
                      {errors.bathrooms && <ErrorText>{errors.bathrooms}</ErrorText>}
                    </InputSeparator>
                  </CCol>
                </CFormGroup>
                <CFormGroup row>
                  <CCol>
                    <InputSeparator>
                      <SettingsModalBoldText>Area</SettingsModalBoldText>
                      <SettingsModalInput
                        label={unitLabels.area}
                        type="number"
                        id="area"
                        className="form-control"
                        name="area"
                        placeholder={t('Area...')}
                        valid={!errors.area && touched.area}
                        onChange={(e) =>
                          e.target.value === 0
                            ? setFieldValue('area', 0)
                            : setFieldValue('area', parseInt(e.target.value))
                        }
                        onBlur={handleBlur}
                        value={values.area}
                        error={errors.area}
                        onKeyDown={(e) =>
                          ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                        }
                      />

                      {errors.area && <ErrorText>{errors.area}</ErrorText>}
                    </InputSeparator>
                  </CCol>
                </CFormGroup>

                {orgConfig?.config?.suite_enabled && (
                  <CFormGroup row>
                    <CCol>
                      <InputSeparator>
                        <SettingsModalBoldText>Unit</SettingsModalBoldText>
                        <Select
                          placeholder="Select Unit"
                          style={{
                            width: '100%'
                          }}
                          maxTagCount="responsive"
                          options={suiteIds.map((unit) => {
                            return {
                              value: unit.id,
                              label: unit.name
                            };
                          })}
                          id="suite"
                          name="suite"
                          onChange={(value) => {
                            setFieldValue('suite', value);
                          }}
                        />
                        {errors.suite && <ErrorText>{errors.suite}</ErrorText>}
                      </InputSeparator>
                    </CCol>
                  </CFormGroup>
                )}

                <CFormGroup row>
                  <CCol>
                    <InputSeparator>
                      <div className="flex justify-content-between">
                        <SettingsModalBoldText>Resident</SettingsModalBoldText>
                        <SettingsItemSubTitle
                          link
                          onClick={() => openModal('AddNewUserTypeModalStep1')}>
                          Add new User
                        </SettingsItemSubTitle>
                      </div>

                      <UserAsyncDropDown
                        value={value}
                        setValue={setValue}
                        buildingId={building.id}
                        type={1}
                        residentOnly={true}
                      />
                      {errors.user_id && <ErrorText>{errors.user_id}</ErrorText>}
                    </InputSeparator>
                  </CCol>
                </CFormGroup>
              </StyledModalBody>
              <ModalFooter
                text={t('Add')}
                error={
                  error && orgConfig?.config?.suite_enabled
                    ? error?.replace(/unit/g, 'room')
                    : error
                }
                handleCancel={handleCancel}
                onClick={() => {}}
                status={status === 'ADD_UNIT_PENDING' ? true : false}
                className={!dirty || !isValid ? '' : 'btn-primary'}
                disabled={isSubmitting || !dirty || !isValid}
                style={
                  isSubmitting || !dirty || !isValid
                    ? {
                        cursor: 'not-allowed'
                      }
                    : { cursor: 'pointer' }
                }
              />
            </StyledModal>
          </CForm>
        )}
      </Formik>
    )
  );
};

export default AddUnit;
