export const DEFAULT_ITEMS_PER_PAGE = 20;

export const ASC = 'ASC';
export const DESC = 'DESC';

export const UserRole = {
  // Roles -  Customer Facing
  USER: 1,
  MANAGER: 2, //Building Manager
  ADMIN: 3, //Org Manager
  ADVERTISER: 4, // Can view,edit,create Deals
  VENDOR: 5, //same access as manager
  // Roles -  Grata Facing
  ACCOUNT_MGR: 80, // can view,edit.create Businesses and their information
  SUPER_ADMIN: 99
};

export const USER_ROLE = {
  GUEST: {
    id: -1,
    value: 'Guest'
  },
  USER: {
    id: 1,
    value: 'Resident'
  },
  MANAGER: {
    id: 2,
    value: 'Building Manager'
  },
  ADMIN: {
    id: 3,
    value: 'Org Manager'
  },
  ADVERTISER: {
    id: 4,
    value: 'Advertiser'
  },
  VENDOR: {
    id: 5,
    value: 'Vendor'
  },
  ACCOUNT_MANAGER: {
    id: 80,
    value: 'Account Manager'
  },
  SUPER_ADMIN: {
    id: 99,
    value: 'Super Admin'
  }
};

export const USER_ROLE_OPTIONS = [
  USER_ROLE.USER,
  USER_ROLE.VENDOR,
  USER_ROLE.MANAGER,
  USER_ROLE.ADMIN,
  USER_ROLE.SUPER_ADMIN
];

export const SERVICE_REQUEST_CATEGORIES = {
  ELECTRICAL: 'Electrical',
  HEATING_COOLING_HOT_WATER: 'Heating/Cooling/Hot Water',
  PEST_CONTROL: 'Pest Control',
  PLUMBING: 'Plumbing'
};

export const shouldShowAddBtn = (userRole, modalName) => {
  if (
    (userRole === UserRole.MANAGER || userRole === UserRole.VENDOR) &&
    modalName !== 'addBuilding'
  )
    return true;

  if (userRole > UserRole.MANAGER && userRole !== UserRole.VENDOR) return true;

  return false;
};

export const getRoleById = (id) => {
  return Object.values(USER_ROLE).find((v) => v.id === id);
};

export const hasPermission = (userRoleId, rolePermissions) => {
  try {
    const parsedUserId = parseInt(userRoleId);
    const parsedRolePermissionId = parseInt(rolePermissions.id);
    return parsedUserId >= parsedRolePermissionId;
  } catch (e) {
    return false;
  }
};

export const isYardiOrganization = (organization) => {
  return organization && organization.prov_service === ORGANIZATION_PROVIDER_SERVICE_TYPE.YARDI;
};

export const isYardiBuilding = (building) => {
  return building && building.yardi_id;
};

export const ORGANIZATION_PROVIDER_SERVICE_TYPE = {
  YARDI: 1
};

export const BUILDING_TYPE = {
  PERMANENT_RESIDENCY: {
    id: 1,
    label: 'Permanent Residency'
  },
  TEMPORARY_RESIDENCY: {
    id: 2,
    label: 'Temporary Residency'
  }
};

export const USER_TYPE = {
  RESIDENT: {
    id: 1,
    value: 'Resident'
  },
  CO_RESIDENT: {
    id: 2,
    value: 'Co-Resident'
  }
};

export const getTypeById = (id) => {
  return Object.values(USER_TYPE).find((v) => v.id === id);
};

export const UserType = {
  RESIDENT: 1,
  CORESIDENT: 2,
  MANAGER: 0,
  VENDOR: 0,
  TECHNICIAN: 0,
  EXTERNAL: 0
};

export const AccountStatus = {
  NEW: 0,
  OPEN: 1,
  PENDING: 2,
  CANCELLED: 3,
  CLOSED: 4,
  COMPLETE: 5,
  RESERVED: 6,
  BOOKED: 7,
  ENABLED: 8,
  DISABLED: 9,
  SENT: 10,
  PROCESSING: 11,
  ERROR: 12,
  INPROGRESS: 13,
  DELETED: 14,
  FAILED: 15,
  INCOMPLETE: 16,
  ARCHIVED: 17
};

const AccountState = [
  {
    id: 0,
    label: 'New'
  },
  {
    id: 2,
    label: 'Pending'
  },
  {
    id: 8,
    label: 'Active'
  },
  {
    id: 14,
    label: 'Archived'
  }
];

export const getAccountStatusById = (id) => {
  return AccountState.find((v) => v.id == id);
};

export const UserTabs = {
  ALL: {
    order: 0,
    label: 'All'
  },
  [AccountStatus.ENABLED]: {
    order: 1,
    label: 'Activated'
  },
  [AccountStatus.NEW]: {
    order: 2,
    label: 'New'
  },
  [AccountStatus.PENDING]: {
    order: 3,
    label: 'Pending'
  },
  [AccountStatus.DELETED]: {
    order: 4,
    label: 'Archived'
  }
};

export const DATE_FORMATS = {
  YYYY_MM_DD: 'YYYY-MM-DD',
  YYYY_MM_DD_hh_mm_A: 'YYYY-MM-DD hh:mm A',
  YYYY_MM_DD_HH_mm_ss: 'YYYY-MM-DD HH:mm:ss',
  MM_DD_YYYY_h_mm_A: 'MM/DD/YYYY h:mm A',
  MM_DD_YYYY: 'MM/DD/YYYY',
  MMM_D_YYYY: 'MMM D, YYYY',
  MMM_DD_YYYY: 'MMM DD, YYYY',
  MMMM_DD_YYYY: 'MMMM DD, YYYY',
  hh_mm_a: 'hh:mm a',
  hh_mm_A: 'hh:mm A',
  H_mm: 'H:mm',
  HH_mm: 'HH:mm',
  HH_mm_ss: 'HH:mm:ss'
};
