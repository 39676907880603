import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setToast } from '../../../../redux/actions/window';
import { clearUnitError, editUnit, getUnits } from '../../../../redux/actions/unit';
import { getUser, clearUser, setUser } from '../../../../redux/actions/user';
import { Formik } from 'formik';
import { CForm, CFormGroup, CRow, CCol } from '@coreui/react';
import * as Yup from 'yup';
import UserAsyncDropDown from '../../../../components/UserAsyncDropDown';
import LoadingAvatar from '../../../../components/LoadingAvatar';
import { changeOpenedModal } from '../../../../redux/actions/headerModal';
import ModalFooter from '../../../../components/ModalFooter';
import { useTranslation } from 'react-i18next';
import { LOADING_BLUE } from '../../../../utils/colorCode';
import {
  InputSeparator,
  SettingsModalBoldText,
  SettingsModalInput
} from '../../../settings/SettingsModals';
import { ModalHeader, StyledModal, StyledModalBody } from '../../../../components/Modals';
import { Select } from '../../../../components/ui/Select';
import { ErrorText } from '../../../../components/utils/AvatarCropper';
import { getBuildingSuites } from '../../../../redux/actions/building';

const EditRoom = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const unitLabels = {
    building: t('Building'),
    room_number: t('Room Number'),
    floor_no: t('Floor'),
    bedrooms: t('Bedrooms'),
    bathrooms: t('Bathrooms'),
    area: t('Area'),
    resident: t('Resident'),
    square_feet: t('Sq.ft'),
    monthly_rent: t('Monthly Rent'),
    primary_resident: t('Primary Resident'),
    co_residents: t('Co-Residents')
  };
  const { modal } = useSelector((state) => state.headerModal);
  const { unit, error, status, pagination } = useSelector((state) => state.unit);
  const { user } = useSelector((state) => state.user);
  const [value, setValue] = useState({});
  const [preValue, setPreValue] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { organization } = useSelector((state) => state.Organization);
  const { suites, building, suitesPagination } = useSelector((state) => state.building);
  const [suiteIds, setSuiteIds] = useState([]);

  const validationSchema = function (values) {
    return Yup.object().shape({
      unit_num: Yup.string().required(
        organization?.config?.suite_enabled
          ? t('Room Number is required')
          : t('Unit Number is required')
      ),
      bedrooms: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(99, t('Not more than 2 characters'))
        .typeError(t('Only numbers')),
      bathrooms: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(99, t('Not more than 2 characters'))
        .typeError(t('Only numbers')),
      area: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(999999, t('Not more than 6 characters'))
        .typeError(t('Only numbers'))
        .notRequired(),
      floor: Yup.number()
        .moreThan(-1, t("Can't be negative!"))
        .max(999, t('Not more than 3 characters'))
        .typeError(t('Only numbers'))
    });
  };

  const validate = (getValidationSchema) => {
    return (values) => {
      const validationSchema = getValidationSchema(values);

      try {
        validationSchema.validateSync(values, { abortEarly: false });
        return {};
      } catch (error) {
        return getErrorsFromValidationError(error);
      }
    };
  };

  const getErrorsFromValidationError = (validationError) => {
    const FIRST_ERROR = 0;
    return validationError.inner.reduce((errors, error) => {
      return {
        ...errors,
        [error.path]: error.errors[FIRST_ERROR]
      };
    }, {});
  };

  const onSubmit = (values) => {
    setIsSubmitting(true);
    const subValues = {
      ...values,
      owned: false,
      user_id: parseInt(value.value),
      area: values?.area || 0
    };

    if (organization?.config?.suite_enabled) {
      subValues.suite_id = values?.suite_id;
      subValues.suite_name = values?.suite_name;
    }

    dispatch(
      editUnit({
        id: unit.id,
        body: subValues,
        success: () => {
          dispatch(
            getBuildingSuites({
              orgId: building.org_id,
              buildingId: building.id,
              page: suitesPagination?.currentPage,
              finally: () => {
                dispatch(
                  getUnits({
                    id: values.building_id,
                    page: pagination.currentPage,
                    perPage: pagination.perPage,
                    sort: pagination.sort,
                    sortOrder: pagination.sortOrder,
                    fail: () => {
                      dispatch(
                        setToast({
                          toastShow: true,
                          toastMessage: organization?.config?.suite_enabled
                            ? t('Fail to retrieve updated rooms!')
                            : t('Fail to retrieve updated rooms!')
                        })
                      );
                    },
                    finally: () => {
                      setIsSubmitting(false);
                      onClose();
                      dispatch(
                        setToast({
                          toastShow: true,
                          toastMessage: organization?.config?.suite_enabled
                            ? t('Room info has been successfully updated!')
                            : t('Unit info has been successfully updated!')
                        })
                      );
                    }
                  })
                );
              }
            })
          );
        },
        fail: () => {
          dispatch(
            setToast({
              toastShow: true,
              toastMessage: t('Unit Updating Failed!'),
              modal: 'errorToast'
            })
          );
          setIsSubmitting(false);
        }
      })
    );
  };

  useEffect(() => {
    if (unit.user_id === 0) {
      dispatch(
        setUser({
          id: 0,
          first_name: 'No',
          last_name: 'user'
        })
      );
    } else {
      dispatch(getUser({ id: unit.user_id }));
    }
  }, [unit]);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      let firstName = user.first_name;
      let lastName = user.last_name;

      if (user.id === 0) {
        firstName = t(user.first_name);
        lastName = t(user.last_name);
      }

      setValue({
        value: user.id,
        label: firstName + ' ' + lastName
      });

      setPreValue({
        value: user.id,
        label: firstName + ' ' + lastName
      });
    }
  }, [user]);

  useEffect(() => {
    if (suites?.length > 0 && organization?.config?.suite_enabled) {
      setSuiteIds(suites.map((suite) => ({ id: suite.id, name: suite.name })));
    }
  }, [suites, organization?.config?.suite_enabled]);

  const onClose = () => {
    dispatch(setUser({}));
    dispatch(clearUser());
    setValue(null);
    dispatch(clearUnitError());
    dispatch(
      changeOpenedModal({
        modal: ''
      })
    );
  };

  const handleCancel = () => {
    onClose();
  };

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={unit}
        validate={validate(validationSchema)}
        onSubmit={onSubmit}>
        {({
          dirty,
          resetForm,
          values,
          isValid,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue
        }) => (
          <CForm onSubmit={handleSubmit} noValidate name="AddUnitForm">
            <StyledModal
              show={modal === 'editRoom'}
              onClose={() => {
                resetForm();
                onClose();
              }}
              closeOnBackdrop={false}>
              <ModalHeader title={t('Edit Room')} />
              {values && Object.keys(values).length ? (
                <StyledModalBody>
                  <CRow className="mt-4">
                    <CCol>
                      <InputSeparator>
                        <SettingsModalBoldText>{unitLabels.building}</SettingsModalBoldText>
                        <SettingsModalInput
                          label={unitLabels.building}
                          type="text"
                          id="building_id"
                          name="building_id"
                          disabled={true}
                          value={values?.building}
                          error={errors.building_id}
                        />
                        {errors.building_id && <ErrorText>{errors.building_id}</ErrorText>}
                      </InputSeparator>
                    </CCol>
                    <CCol>
                      <InputSeparator>
                        <SettingsModalBoldText>
                          {organization?.config?.suite_enabled ? 'Room Num' : 'Unit Number'}
                        </SettingsModalBoldText>
                        <SettingsModalInput
                          label={unitLabels.room_number}
                          type="text"
                          id="unit_num"
                          name="unit_num"
                          placeholder={
                            organization?.config?.suite_enabled
                              ? t('Room Num...')
                              : t('Unit Num...')
                          }
                          valid={!errors.unit_num}
                          invalid={touched.unit_num && !!errors.unit_num}
                          required
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.unit_num}
                          error={errors.unit_num}
                        />
                        {errors.unit_num && <ErrorText>{errors.unit_num}</ErrorText>}
                      </InputSeparator>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <InputSeparator>
                        <SettingsModalBoldText>{unitLabels.floor_no}</SettingsModalBoldText>
                        <SettingsModalInput
                          label={unitLabels.floor_no}
                          type="number"
                          id="floor"
                          name="floor"
                          placeholder={t('Floor...')}
                          valid={!errors.floor}
                          invalid={touched.floor && !!errors.floor}
                          onKeyDown={(e) =>
                            ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                          }
                          onChange={(e) =>
                            e.target.value === 0
                              ? setFieldValue('floor', 0)
                              : setFieldValue('floor', parseInt(e.target.value))
                          }
                          onBlur={handleBlur}
                          value={values.floor}
                          error={errors.floor}
                        />
                        {errors.floor && <ErrorText>{errors.floor}</ErrorText>}
                      </InputSeparator>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <InputSeparator>
                        <SettingsModalBoldText>{unitLabels.bedrooms}</SettingsModalBoldText>
                        <SettingsModalInput
                          onKeyDown={(e) =>
                            ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                          }
                          label={unitLabels.bedrooms}
                          type="number"
                          id="bedrooms"
                          name="bedrooms"
                          placeholder={t('Bedrooms...')}
                          valid={!errors.bedrooms}
                          invalid={touched.bedrooms && !!errors.bedrooms}
                          onChange={(e) =>
                            e.target.value === 0
                              ? setFieldValue('bedrooms', 0)
                              : setFieldValue('bedrooms', parseInt(e.target.value))
                          }
                          onBlur={handleBlur}
                          value={values.bedrooms}
                          error={errors.bedrooms}
                        />
                        {errors.bedrooms && <ErrorText>{errors.bedrooms}</ErrorText>}
                      </InputSeparator>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <InputSeparator>
                        <SettingsModalBoldText>{unitLabels.bathrooms}</SettingsModalBoldText>
                        <SettingsModalInput
                          onKeyDown={(e) =>
                            ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                          }
                          label={unitLabels.bathrooms}
                          type="number"
                          id="bathrooms"
                          name="bathrooms"
                          placeholder={t('Bathrooms...')}
                          valid={!errors.bathrooms}
                          invalid={touched.bathrooms && !!errors.bathrooms}
                          onChange={(e) =>
                            e.target.value === 0
                              ? setFieldValue('bathrooms', 0)
                              : setFieldValue('bathrooms', parseInt(e.target.value))
                          }
                          onBlur={handleBlur}
                          value={values.bathrooms}
                          error={errors.bathrooms}
                        />
                        {errors.bathrooms && <ErrorText>{errors.bathrooms}</ErrorText>}
                      </InputSeparator>
                    </CCol>
                  </CRow>
                  <CRow>
                    <CCol>
                      <InputSeparator>
                        <SettingsModalBoldText>{unitLabels.area}</SettingsModalBoldText>
                        <SettingsModalInput
                          onKeyDown={(e) =>
                            ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault()
                          }
                          label={unitLabels.area}
                          type="number"
                          id="area"
                          name="area"
                          placeholder={t('Area...')}
                          valid={!errors.area}
                          invalid={touched.area && !!errors.area}
                          onChange={(e) =>
                            e.target.value === 0 || e.target.value === ''
                              ? setFieldValue('area', undefined)
                              : setFieldValue('area', parseInt(e.target.value))
                          }
                          onBlur={handleBlur}
                          value={values.area}
                          error={errors.area}
                        />
                        {errors.area && <ErrorText>{errors.area}</ErrorText>}
                      </InputSeparator>
                    </CCol>
                  </CRow>

                  {organization?.config?.suite_enabled && (
                    <CFormGroup row>
                      <CCol>
                        <InputSeparator>
                          <SettingsModalBoldText>Unit</SettingsModalBoldText>
                          <Select
                            placeholder="Select Unit"
                            style={{
                              width: '100%'
                            }}
                            maxTagCount="responsive"
                            options={suiteIds.map((unit) => {
                              return {
                                value: unit.id,
                                label: unit.name
                              };
                            })}
                            id="suite"
                            name="suite"
                            value={
                              values?.suite_id && values?.suite_name !== ''
                                ? { value: values.suite_id, label: values.suite_name }
                                : undefined
                            }
                            onChange={(value, { label }) => {
                              setFieldValue('suite_id', value);
                              setFieldValue('suite_name', label);
                            }}
                          />
                          {errors.suite && <ErrorText>{errors.suite}</ErrorText>}
                        </InputSeparator>
                      </CCol>
                    </CFormGroup>
                  )}

                  <CFormGroup row>
                    <CCol>
                      <InputSeparator>
                        <SettingsModalBoldText>{unitLabels.resident}</SettingsModalBoldText>
                        <UserAsyncDropDown
                          value={value}
                          setValue={setValue}
                          type={1}
                          buildingId={values?.building_id}
                          residentOnly={true}
                        />
                        {errors.user_id && <ErrorText>{errors.user_id}</ErrorText>}
                      </InputSeparator>
                    </CCol>
                  </CFormGroup>
                </StyledModalBody>
              ) : (
                <LoadingAvatar color={LOADING_BLUE} />
              )}

              <ModalFooter
                text={t('Edit')}
                error={
                  error && organization?.config?.suite_enabled
                    ? error?.replace(/unit/g, 'room')
                    : error
                }
                handleCancel={handleCancel}
                onClick={() => {}}
                status={status === 'EDIT_UNIT_PENDING' ? true : false}
                style={
                  (dirty || JSON.stringify(preValue) !== JSON.stringify(value)) &&
                  isValid &&
                  !isSubmitting
                    ? { cursor: 'pointer' }
                    : { cursor: 'not-allowed' }
                }
                disabled={
                  !((dirty || JSON.stringify(preValue) !== JSON.stringify(value)) && isValid) ||
                  isSubmitting
                }
                className={
                  (dirty || JSON.stringify(preValue) !== JSON.stringify(value)) && isValid
                    ? 'btn btn-primary'
                    : 'btn'
                }
              />
            </StyledModal>
          </CForm>
        )}
      </Formik>
    </>
  );
};

export default EditRoom;
